<!--
项目字段设置以及相关方管理（相关方类型管理，相关方等级管理）通用
-->
<template>
  <div class="container">
    <div class="title">{{ $route.meta.name }}</div>
    <div class="query-nav">
      <el-form :model="queryForm" inline>
        <el-form-item>
          <el-input v-model.trim="queryForm.name" :placeholder="this.textName" style="width:300px"></el-input>
        </el-form-item>
        <el-button type="primary" @click="handleSearch">搜索</el-button>
        <el-button @click="handleReset">重置</el-button>
      </el-form>
    </div>
    <div class="buts">
      <el-button type="primary" @click="addList"><i class="el-icon-plus"></i>新建</el-button>
      <div>
        <el-button @click="handleExportTableList" :loading="expoxtLoading" :disabled='!ids.length'>导出</el-button>
        <el-button type="danger" style="margin-left:16px" @click="handelBatchDelete" :disabled='!ids.length'>批量删除</el-button>
      </div>
    </div>
    <div class="table">
      <el-table
        size="medium"
        v-loading="tableLoading"
        :data="tabelList"
        @selection-change="handleSelectionChange"
        stripe
        ref="tabelRef"
      >
        <el-table-column type="selection" width="60" fixed align="center" :resizable="false"></el-table-column>
        <el-table-column width="60" fixed type="index" label="序号" align="center" :resizable="false"></el-table-column>
        <el-table-column
          show-overflow-tooltip
          fixed
          prop="name"
          :label="this.textName"
          align="center"
          :resizable="false"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="baseDescribe"
          :label="typeDescription"
          align="center"
          :resizable="false"
        >
          <template slot-scope="{ row }">
            {{ row.baseDescribe || '-' }}
          </template></el-table-column
        >
        <el-table-column show-overflow-tooltip prop="createTime" label="创建时间" align="center" :resizable="false">
          <template slot-scope="{ row }">
            {{ row.createTime.substr(0, 16) }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" align="center" :resizable="false">
          <template slot-scope="{ row }">
            <div class="comm-operation">
              <span class="edit-btn mr-16" @click="update(row)">修改</span>
              <span class="delete-btn" slot="reference" @click="handelSingleDelete(row)">删除</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页区域 -->
    <div class="page">
      <el-pagination
        :total="total"
        :current-page="queryForm.pageNo"
        :page-size="queryForm.pageSize"
        :page-sizes="[10, 20, 30, 50]"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </div>
    <!-- 新增 -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="590px"
      @close="closeDialog"
      :close-on-click-modal="false"
    >
      <el-form ref="formRef" :model="dialogQueryForm" label-width="135px" :rules="rules">
        <el-form-item prop="name" :label="this.textName">
          <el-input v-model.trim="dialogQueryForm.name"></el-input>
        </el-form-item>

        <el-form-item :label="typeDescription">
          <el-input
            type="textarea"
            v-model.trim="dialogQueryForm.baseDescribe"
            :rows="4"
            :maxlength="200"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="handlerForm">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getTabelList, addOrEdit, deleteData } from 'api/common/common';
import { exportMethods } from 'utils/exportMethods';
export default {
  name: 'TabelTemplate',
  props: {
    // 1项目类型 2项目状态 3档案类型 4相关方类型 5相关方等级 6质量巡检内容 7安全巡检内容 8合同类型
    type: {
      type: Number
    }
  },
  data() {
    return {
      dialogVisible: false,
      tableLoading: false,
      expoxtLoading: false,
      total: 0,
      queryForm: {
        pageNo: 1,
        pageSize: 10,
        type: this.type,
        name: ''
      },
      ids: [], // 删除id集合
      /* 表单字段 */
      dialogQueryForm: {
        id: '',
        name: '',
        baseDescribe: '',
        type: this.type
      },
      dialogTitle: '',
      // 表单验证
      rules: {
        name: [
          { required: true, message: '请输入', trigger: 'blur' },
          { min: 0, max: 200, message: '长度在200个字符内', trigger: 'blur' }
        ]
      },
      tabelList: [] // 表格数据
    };
  },
  methods: {
    handleExportTableList() {
      if (!this.ids.length) {
        this.$message.warning('请先勾选，再执行本操作');
      } else {
        this.exportFunc(this.ids);
      }
    },
    // 导出方法
    exportFunc(ids) {
      this.expoxtLoading = true;
      exportMethods('/typeBase/export', { ids, type: this.type }, this.$route.meta.name)
        .then(() => {
          this.expoxtLoading = false;
          this.$refs.tabelRef.clearSelection();
        })
        .catch(() => {
          this.expoxtLoading = false;
        });
    },
    // 新增
    addList() {
      this.dialogQueryForm = {
        id: '',
        name: '',
        baseDescribe: '',
        type: this.type
      };
      this.dialogTitle = '新增';
      this.dialogVisible = true;
    },
    // 修改
    update(row) {
      const { name, id, baseDescribe } = row;
      this.dialogTitle = '修改';
      this.dialogVisible = true;
      this.dialogQueryForm.id = id;
      this.dialogQueryForm.name = name;
      this.dialogQueryForm.baseDescribe = baseDescribe;
    },
    // 列表
    getTabelList() {
      this.tableLoading = true;
      getTabelList(this.queryForm).then(res => {
        this.tableLoading = false;
        this.total = res.data.total;
        this.tabelList = res.data.records;
      });
    },
    closeDialog() {
      this.dialogVisible = false;
      this.$refs.formRef.resetFields();
    },
    handleSearch() {
      this.queryForm.pageNo = 1;
      this.getTabelList();
    },
    // 重置
    handleReset() {
      this.queryForm = {
        pageNo: 1,
        pageSize: 10,
        type: this.type,
        name: ''
      };
      this.getTabelList();
    },
    // 单个删除
    handelSingleDelete({ id }) {
      this.$confirm(
        `是否确认删除该${this.$route.meta.name.substr(0, this.$route.meta.name.length - 2)}，是否继续？`,
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(() => {
          const ids = [];
          ids.push(id);
          this.deleteFunc(ids);
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
    },
    // 批量删除
    handelBatchDelete() {
      if (!this.ids.length) {
        this.$message.warning('请先勾选，再执行本操作');
      } else {
        this.$confirm(
          `是否确认删除该${this.$route.meta.name.substr(0, this.$route.meta.name.length - 2)}，是否继续？`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
        )
          .then(() => {
            this.deleteFunc(this.ids);
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            });
          });
      }
    },
    // 删除接口方法
    deleteFunc(ids) {
      deleteData({ ids, type: this.type }).then(res => {
        if (res.code === 200) {
          this.$message.success('操作成功');
          this.$refs.tabelRef.clearSelection();
          this.getTabelList();
        }
      });
    },
    handleSelectionChange(row) {
      this.ids = [];
      row.map(item => {
        this.ids.push(item.id);
      });
    },
    // 分页
    handleSizeChange(pageSize) {
      this.queryForm.pageSize = pageSize;
      this.queryForm.pageNo = 1;
      this.getTabelList();
    },
    handleCurrentChange(current) {
      this.queryForm.pageNo = current;
      this.getTabelList();
    },
    // 保存
    handlerForm() {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          addOrEdit(this.dialogQueryForm).then(res => {
            if (res.code === 200) {
              this.$message.success('操作成功');
              this.dialogVisible = false;
              this.getTabelList();
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    }
  },
  created() {
    this.getTabelList();
  },
  computed: {
    // 1项目类型 2项目状态 3档案类型 4相关方类型 5相关方等级 6质量巡检内容 7安全巡检内容 8合同类型
    textName() {
      switch (this.type) {
        case 1:
          return '项目类型名称';
        case 2:
          return '项目状态名称';
        case 3:
          return '项目档案类型名称';
        case 4:
          return '相关方类型名称';
        case 5:
          return '相关方等级名称';
        case 6:
          return '质量巡检内容名称';
        case 7:
          return '安全巡检内容名称';
        default:
          return '合同类型名称';
      }
    },
    // 描述
    typeDescription() {
      switch (this.type) {
        case 1:
        case 3:
        case 4:
        case 8:
          return '类型描述';
        case 2:
          return '状态描述';
        case 5:
          return '等级描述';
        case 6:
          return '巡检内容描述';
        case 7:
          return '巡检内容描述';
      }
    }
  }
};
</script>
<style lang="less" scoped>
.container {
  .page {
    margin: 16px 0;
    float: right;
  }
}
.query-nav {
  border-bottom: 1px solid #dcdfe7;
  border-top: 1px solid #dcdfe7;
  padding-top: 24px;
}
.title {
  padding: 0 16px 12px;
  font-size: 16px;
  font-weight: bold;
  color: #303133;
}
.table {
  margin-top: 12px;
}
.buts {
  margin-top: 24px;
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  span {
    font-size: 14px;
    font-weight: 400;
    color: #117bf7;
  }
}
</style>
